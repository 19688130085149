<template>
  <v-container class="general">
    <page-title :component="'ReportListPage'">
      <template slot="actions">
        <VideoTutorial
          :component="'ReportListPage'"
          :title="$t('reportsTutorialTitle')"
        />
      </template>
    </page-title>
  <div>
    <div v-if="!ready" class="text-xs-center py-2">
      <v-progress-circular
        indeterminate
        class="theme-accent"
      ></v-progress-circular>
    </div>
    <v-layout v-if="ready">
      <div class="overflow-auto">
        <v-flex xs12 class="reports" v-html="repostHtml"></v-flex>
      </div>
    </v-layout>
    <v-layout v-if="ready">
      <v-flex xs3>
        <v-btn
          round
          large
          class="ml-0 mr-3 white sw-accent text-none"
          @click="$router.push({ name: 'reports' })"
          >{{ $t("back") }}</v-btn
        >
      </v-flex>
      <v-flex xs9 align-end class="text-right">
        <span> Export: </span>
        <!-- PDF -->
        <v-tooltip top>
          <v-btn
            slot="activator"
            icon
            class="ma-0"
            @click="exportReport('pdf')"
          >
            <font-awesome-icon class="sw-accent" icon="file-pdf" /> 
          </v-btn>
          <span>{{ $t("download") }} PDF</span>
        </v-tooltip>
        <!-- HTML -->
        <v-tooltip top>
          <v-btn
            slot="activator"
            icon
            class="ma-0"
            @click="exportReport('html')"
          >
            <font-awesome-icon class="sw-accent" icon="file-code" />
          </v-btn>
          <span>{{ $t("download") }} HTML</span>
        </v-tooltip>
        <!-- XLSX -->
        <v-tooltip top>
          <v-btn
            slot="activator"
            icon
            class="ma-0"
            @click="exportReport('xlsx')"
          >
            <font-awesome-icon class="sw-accent" icon="file-excel" />
          </v-btn>
          <span>{{ $t("download") }} XLS</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
  </div>
  </v-container>
</template>

<script>
import axios from "axios";
import fileDownload from "js-file-download";
export default {
  data() {
    return {
      ready: false,
      repostHtml: '',
    };
  },
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
     reportId() {
      return this.$route.params.report_id;
    },
  },
  mounted() {
    this.exportReport('view');
  },
  methods: {
    exportReport(format = "html") {
      const filename = 'report_name'.replace(/[^a-z0-9]/gi, "_") + "." + format;
      axios({
        url:
          process.env.VUE_APP_API_URL +
          "groups/" +
          this.groupId +
          "/reports/" +
          this.reportId +
          "/export?format=" +
          (format=='view' ? 'html' : format),
        method: "GET",
        ...(format !== 'view' && { responseType: "blob" }),
      })
        .then((response) => {
          if(format == 'view'){
            this.repostHtml = response.data;
            this.ready = true; 
          } else {
            fileDownload(response.data, filename);
          }
        })
        .catch((error) => {
          this.$store.dispatch("addErrorNotification", {
            message: (error & error.response && error.response.data && error.response.data.error)? error.response.data.error.message : this.$t("internalServerError"),
          });
        });
    },
  },
};
</script>
<style lang="scss">
.search-input.body-1{
  max-width: 100%;
}
.reports table {
  width: 100% !important;
}
div.overflow-auto {
  overflow: auto !important;
  min-width: 100% !important;
}

.gridlines td, tr td[class*="style"] {
 border: 1px solid #edebeb !important;
 padding: 16px !important;
  font-family: "Poppins", Arial, Helvetica, sans-serif !important;
  min-width: 180px !important;
}

td[class*="style"] {
    vertical-align: middle !important;
}
</style>
